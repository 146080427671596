// Utils actions
export const SET_WIDTH = 'SET_WIDTH';
export const DATABASE_EDIT_SUBMIT = 'DATABASE_EDIT_SUBMIT';
export const DATABASE_EDIT_FINISH = 'DATABASE_EDIT_FINISH';

// Main actions
export const LISTEN_TO_JOB_OFFER_STARTED = 'LISTEN_TO_JOB_OFFER_STARTED';
export const LISTEN_TO_JOB_OFFER_RECEIVED_DATA = 'LISTEN_TO_JOB_OFFER_RECEIVED_DATA';
export const LISTEN_TO_JOB_OFFER_RECEIVED_ERROR = 'LISTEN_TO_JOB_OFFER_RECEIVED_ERROR';

// Home actions
export const SET_QUERY = 'SET_QUERY';
